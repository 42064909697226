import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_btn_dropdown = _resolveComponent("q-btn-dropdown")!
  const _component_q_table = _resolveComponent("q-table")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_table, {
    class: _normalizeClass([
      'AppFullViewTable',
      _ctx.$style.stickyHeader,
      _ctx.customHeight && _ctx.$style.customHeight,
    ]),
    loading: _ctx.loading,
    filter: _ctx.filterText,
    "rows-per-page-options": [0],
    rows: _ctx.rows,
    columns: _ctx.columns,
    "row-key": "uuid",
    flat: "",
    dense: ""
  }, {
    top: _withCtx(() => [
      _renderSlot(_ctx.$slots, "top"),
      _createVNode(_component_q_separator, { vertical: "" }),
      _createVNode(_component_q_input, {
        style: {"width":"100%","max-width":"300px"},
        class: "q-px-md",
        debounce: "300",
        modelValue: _ctx.filterText,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterText) = $event)),
        placeholder: "Sök...",
        borderless: ""
      }, {
        append: _withCtx(() => [
          _createVNode(_component_q_icon, { name: "mdi-magnify" })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_q_separator, { vertical: "" }),
      _createVNode(_component_q_btn, {
        loading: _ctx.exporting,
        onClick: _ctx.onExport,
        stretch: "",
        flat: "",
        "icon-right": "mdi-file-export-outline",
        label: "Export",
        color: "grey-7"
      }, null, 8, ["loading", "onClick"]),
      _createVNode(_component_q_separator, { vertical: "" }),
      _createVNode(_component_q_btn, {
        onClick: _ctx.onPrint,
        stretch: "",
        flat: "",
        "icon-right": "mdi-printer",
        color: "grey-7"
      }, null, 8, ["onClick"])
    ]),
    "body-cell-prognosis": _withCtx((props) => [
      _createVNode(_component_q_td, { props: props }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "body-cell-prognosis", _normalizeProps(_guardReactiveProps(props)), () => [
            _createTextVNode(_toDisplayString(props.value), 1)
          ])
        ]),
        _: 2
      }, 1032, ["props"])
    ]),
    "body-cell-_action": _withCtx((props) => [
      _createVNode(_component_q_td, { props: props }, {
        default: _withCtx(() => [
          _createVNode(_component_q_btn_dropdown, {
            size: "sm",
            flat: "",
            round: "",
            color: "grey-7",
            "dropdown-icon": "mdi-dots-vertical",
            "content-class": "no-border-radius"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_list, {
                dark: "",
                class: "bg-dark"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actionItems?.filter((x) =>
                _ctx.filterActionItems(x, props.row)
              ), (actionItem) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                      key: actionItem.label
                    }, [
                      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                        clickable: "",
                        dense: "",
                        onClick: ($event: any) => (actionItem.action(props.row))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_item_section, { avatar: "" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_icon, {
                                size: "xs",
                                name: actionItem.icon
                              }, null, 8, ["name"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_q_item_section, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_item_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(actionItem.label), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])), [
                        [_directive_close_popup]
                      ]),
                      _createVNode(_component_q_separator, {
                        dark: "",
                        color: "grey-9"
                      })
                    ], 64))
                  }), 128))
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1032, ["props"])
    ]),
    _: 3
  }, 8, ["class", "loading", "filter", "rows", "columns"]))
}