import { client } from '@/services/api-client'
import { VehicleWithdrawal } from '@/types/VehicleWithdrawal'
import { gql } from 'graphql-request'
import { startOfDay, endOfDay } from 'date-fns'
import { removeTimezoneFromDateString } from '@/common/removeTimezoneFromDateString'

interface Period {
  from: string
  to: string
}

export const getHistory = (period: Period): Promise<VehicleWithdrawal[]> =>
  client
    .request<{
      vehicle_withdrawal_history: VehicleWithdrawal[]
    }>(
      gql`
        query getHistory($input: VehicleWithdrawalHistoryInput!) {
          vehicle_withdrawal_history(input: $input) {
            uuid
            vehicle_uuid
            event_id
            wo
            # vehicle_withdrawal_cause_uuid
            vehicle_withdrawal_reason_uuid
            vehicle_withdrawal_responsible_uuid
            vehicle_withdrawal_depot_uuid
            vehicle {
              uuid
              name
              vehicle_type {
                uuid
                name
              }
            }
            vehicle_compositions {
              uuid
              vehicles {
                name
              }
            }
            withdrawn
            prognosis
            ready
            comment
            logs {
              created
              method
              user
              payload
              change
            }
          }
        }
      `,
      {
        input: {
          from: startOfDay(new Date(period.from)),
          to: endOfDay(new Date(period.to)),
        },
      }
    )
    .then((data) =>
      data.vehicle_withdrawal_history.map((x) => ({
        ...x,
        prognosis: x.prognosis
          ? removeTimezoneFromDateString(x.prognosis)
          : null,
        withdrawn: x.withdrawn ? removeTimezoneFromDateString(x.withdrawn) : '',
        ready: x.ready ? removeTimezoneFromDateString(x.ready) : '',
        // logs: x.logs.map((log) => ({
        //   ...log,
        //   created: removeTimezoneFromDateString(log.created),
        // })),
      }))
    )
