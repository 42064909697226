
import { computed, defineComponent, ref } from 'vue'
import AppFullViewTable from '@/components/AppFullViewTable.vue'
import AppFieldDate from '@/components/AppFieldDate.vue'
import QTable from 'quasar/src/components/table/QTable.js';import useQuasar from 'quasar/src/composables/use-quasar.js';

import { getHistory } from '@/api/history/getHistory'
import { getVehicleWithdrawalResponsible } from '@/api/vehicle-withdrawal/getVehicleWithdrawalResponsible'
// import { getVehicleWithdrawalCause } from '@/api/vehicle-withdrawal/getVehicleWithdrawalCause'
import { getVehicleWithdrawalReason } from '@/api/vehicle-withdrawal/getVehicleWithdrawalReason'
import { getVehicleWithdrawalDepot } from '@/api/vehicle-withdrawal/getVehicleWithdrawalDepot'
import { VehicleWithdrawalCause } from '@/types/VehicleWithdrawalCause'
import { VehicleWithdrawalReason } from '@/types/VehicleWithdrawalReason'
import { VehicleWithdrawalResponsible } from '@/types/VehicleWithdrawalResponsible'
import { VehicleWithdrawalDepot } from '@/types/VehicleWithdrawalDepot'
import { VehicleWithdrawal } from '@/types/VehicleWithdrawal'
import { Vehicle } from '@/types/Vehicle'
import { format, isAfter, isValid, subDays } from 'date-fns'
import VehicleWithdrawalLogModal from '@/components/vehicle-withdrawal/VehicleWithdrawalLogModal.vue'

export default defineComponent({
  name: 'HistoryTable',

  components: {
    AppFullViewTable,
    AppFieldDate,
    VehicleWithdrawalLogModal,
  },

  setup() {
    const $q = useQuasar()
    const showCreateModal = ref(false)
    const showEditModal = ref(false)
    const showLogModal = ref(false)
    const showConfirmReadyModal = ref(false)
    const loading = ref(false)
    const selectedVehicleWithdrawal = ref<VehicleWithdrawal | null>(null)
    const filterReadyTypes = [
      { name: 'Alla', value: 'all' },
      { name: 'Påställda', value: 'ready' },
      { name: 'Avställda', value: 'withdrawn' },
    ]
    const selectedFilterReady = ref('withdrawn')
    const period = ref({
      from: format(subDays(new Date(), 7), 'yyyy-MM-dd'),
      to: format(new Date(), 'yyyy-MM-dd'),
    })

    const vehicleWithdrawal = ref<VehicleWithdrawal[]>([])
    const vehicleWithdrawalCause = ref<VehicleWithdrawalCause[]>([])
    const vehicleWithdrawalReason = ref<VehicleWithdrawalReason[]>([])
    const vehicleWithdrawalResponsible = ref<VehicleWithdrawalResponsible[]>([])
    const vehicleWithdrawalDepot = ref<VehicleWithdrawalDepot[]>([])
    const vehicles = ref<Vehicle[]>([])

    const columns: QTable['columns'] = [
      {
        align: 'left',
        name: '_trainComposition',
        label: 'Tågsätt',
        field: (x: VehicleWithdrawal) =>
          x.vehicle_compositions
            .map((vc) => vc.vehicles.map((v) => v.name).join(' '))
            .join(' | '),
        sortable: true,
      },
      {
        align: 'left',
        name: 'littera',
        label: 'Littera',
        field: (x: VehicleWithdrawal) => x.vehicle.vehicle_type?.name || '',
        sortable: true,
      },
      {
        align: 'left',
        name: 'vehicle',
        label: 'Fordon',
        field: (x: VehicleWithdrawal) => x.vehicle.name,
        sortable: true,
      },
      {
        align: 'left',
        name: 'vehicleWithdrawalResponsible',
        label: 'Ansvarig',
        field: 'vehicleWithdrawalResponsible',
        sortable: true,
      },
      {
        align: 'left',
        name: 'vehicleWithdrawalDepot',
        label: 'Avställningsdepå',
        field: 'vehicleWithdrawalDepot',
        sortable: true,
      },
      {
        align: 'left',
        name: 'withdrawn',
        label: 'Avställd',
        field: 'withdrawn',
        format: (x: string | undefined) =>
          x ? format(new Date(x), 'yyyy-MM-dd HH:mm') : '',
        sortable: true,
      },
      {
        align: 'left',
        name: 'prognosis',
        label: 'Prognos',
        field: 'prognosis',
        format: (x: string | undefined) =>
          x ? format(new Date(x), 'yyyy-MM-dd HH:mm') : '',
        sortable: true,
      },
      {
        align: 'left',
        name: 'ready',
        label: 'Påställd',
        field: 'ready',
        format: (x: string | undefined) =>
          x ? format(new Date(x), 'yyyy-MM-dd HH:mm') : '',
        sortable: true,
      },
      {
        align: 'left',
        name: 'comment',
        label: 'Kommentar',
        field: 'comment',
        sortable: true,
      },
      {
        align: 'left',
        name: 'vehicleWithdrawalReason',
        label: 'Anledning',
        field: 'vehicleWithdrawalReason',
        sortable: true,
      },
      {
        align: 'left',
        name: 'wo',
        label: 'Workorder',
        field: 'wo',
        sortable: true,
      },
      {
        align: 'left',
        name: 'event_id',
        label: 'Händelse ID',
        field: 'event_id',
        sortable: true,
      },
      // {
      //   align: 'left',
      //   name: 'vehicleWithdrawalCause',
      //   label: 'Orsak',
      //   field: 'vehicleWithdrawalCause',
      //   sortable: true,
      // },
      {
        align: 'right',
        name: '_action',
        label: '',
        field: '_action',
        sortable: false,
      },
    ]

    async function fetchVehicleWithdrawal() {
      loading.value = true
      vehicleWithdrawal.value = await getHistory(period.value)
      loading.value = false
    }

    async function getData() {
      loading.value = true
      vehicleWithdrawalResponsible.value =
        await getVehicleWithdrawalResponsible()
      // vehicleWithdrawalCause.value = await getVehicleWithdrawalCause()
      vehicleWithdrawalReason.value = await getVehicleWithdrawalReason()
      vehicleWithdrawalDepot.value = await getVehicleWithdrawalDepot()
      await fetchVehicleWithdrawal()
      loading.value = false
    }

    const rows = computed(() => {
      const list = vehicleWithdrawal.value.map((x) => ({
        ...x,
        vehicleWithdrawalReason: vehicleWithdrawalReason.value.find(
          (y) => y.uuid === x.vehicle_withdrawal_reason_uuid
        )?.name,
        vehicleWithdrawalCause: vehicleWithdrawalCause.value.find(
          (y) => y.uuid === x.vehicle_withdrawal_cause_uuid
        )?.name,
        vehicleWithdrawalDepot: vehicleWithdrawalDepot.value.find(
          (y) => y.uuid === x.vehicle_withdrawal_depot_uuid
        )?.name,
        vehicleWithdrawalResponsible: vehicleWithdrawalResponsible.value.find(
          (y) => y.uuid === x.vehicle_withdrawal_responsible_uuid
        )?.name,
      }))
      type Vehicle = { vehicle: { name: string } }
      const sortVehicleNumber = (a: Vehicle, b: Vehicle) =>
        parseInt(a.vehicle.name) - parseInt(b.vehicle.name)
      const filterVehicleStartNumber = (stringNumber: string) => (x: Vehicle) =>
        x.vehicle.name[0] === stringNumber
      return [
        ...list.filter(filterVehicleStartNumber('2')).sort(sortVehicleNumber),
        ...list.filter(filterVehicleStartNumber('8')).sort(sortVehicleNumber),
        ...list.filter(filterVehicleStartNumber('1')).sort(sortVehicleNumber),
        ...list.filter(filterVehicleStartNumber('7')).sort(sortVehicleNumber),
      ]
    })

    const getFilterReadyTypeLabel = computed(() => {
      return (
        filterReadyTypes.find((x) => x.value === selectedFilterReady.value)
          ?.name || '-'
      )
    })

    const actionItems = [
      // {
      //   label: 'Redigera',
      //   icon: 'mdi-pencil',
      //   action: (item: VehicleWithdrawal) => {
      //     selectedVehicleWithdrawal.value = item
      //     showEditModal.value = true
      //   },
      // },
      {
        label: 'Logg',
        icon: 'mdi-view-list',
        action: (item: VehicleWithdrawal) => {
          selectedVehicleWithdrawal.value = item
          showLogModal.value = true
        },
      },
      // {
      //   label: 'Påställ',
      //   icon: 'mdi-check',
      //   action: (item: VehicleWithdrawal) => {
      //     selectedVehicleWithdrawal.value = item
      //     showConfirmReadyModal.value = true
      //   },
      // },
    ]

    getData()

    function isPrognosisOverdue(ready: string | null, prognosisDate: string) {
      return !ready && isAfter(new Date(), new Date(prognosisDate))
    }

    async function fetchHistory() {
      console.log(period.value.from)
      if (
        !isValid(new Date(period.value.from)) ||
        !isValid(new Date(period.value.to)) ||
        period.value.from.length !== 10 ||
        period.value.to.length !== 10
      ) {
        return $q.notify({
          type: 'warning',
          position: 'center',
          message: 'Felaktigt datum period! Ändra och hämta igen.',
          timeout: 2000,
          actions: [
            {
              label: 'OK',
              color: 'black',
            },
          ],
        })
      }

      if (isAfter(new Date(period.value.from), new Date(period.value.to))) {
        const from = period.value.from
        const to = period.value.to

        period.value.from = to
        period.value.to = from
      }
      getData()
    }

    return {
      columns,
      rows,
      loading,
      period,
      filterReadyTypes,
      selectedFilterReady,
      getFilterReadyTypeLabel,
      actionItems,
      showCreateModal,
      showEditModal,
      showLogModal,
      showConfirmReadyModal,

      vehicleWithdrawalCause,
      vehicleWithdrawalReason,
      vehicleWithdrawalDepot,
      vehicleWithdrawalResponsible,
      vehicles,

      fetchVehicleWithdrawal,
      selectedVehicleWithdrawal,
      isPrognosisOverdue,
      fetchHistory,
    }
  },
})
