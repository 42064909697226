import { client } from '@/services/api-client'
import { VehicleWithdrawalResponsible } from '@/types/VehicleWithdrawalResponsible'
import { gql } from 'graphql-request'

export const getVehicleWithdrawalResponsible = (): Promise<
  VehicleWithdrawalResponsible[]
> =>
  client
    .request<{
      vehicle_withdrawal_responsible: VehicleWithdrawalResponsible[]
    }>(
      gql`
        query getVehicleWithdrawalResponsible {
          vehicle_withdrawal_responsible {
            uuid
            name
          }
        }
      `
    )
    .then((data) => data.vehicle_withdrawal_responsible)
