import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VehicleWithdrawalLogModal = _resolveComponent("VehicleWithdrawalLogModal")!
  const _component_AppFieldDate = _resolveComponent("AppFieldDate")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_AppFullViewTable = _resolveComponent("AppFullViewTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showLogModal)
      ? (_openBlock(), _createBlock(_component_VehicleWithdrawalLogModal, {
          key: 0,
          onClose: _cache[0] || (_cache[0] = () => (_ctx.showLogModal = false)),
          "selected-vehicle-withdrawal": _ctx.selectedVehicleWithdrawal,
          causes: _ctx.vehicleWithdrawalCause,
          reasons: _ctx.vehicleWithdrawalReason,
          depots: _ctx.vehicleWithdrawalDepot,
          responsibles: _ctx.vehicleWithdrawalResponsible,
          vehicles: _ctx.vehicles
        }, null, 8, ["selected-vehicle-withdrawal", "causes", "reasons", "depots", "responsibles", "vehicles"]))
      : _createCommentVNode("", true),
    _createVNode(_component_AppFullViewTable, {
      columns: _ctx.columns,
      rows: _ctx.rows,
      loading: _ctx.loading,
      "action-items": _ctx.actionItems,
      "export-file-name": "Historik"
    }, {
      top: _withCtx(() => [
        _createVNode(_component_AppFieldDate, {
          modelValue: _ctx.period.from,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.period.from) = $event)),
          "hide-bottom-space": "",
          label: "Från",
          class: "q-mr-sm q-ml-md",
          borderless: ""
        }, null, 8, ["modelValue"]),
        _createVNode(_component_q_separator, { vertical: "" }),
        _createVNode(_component_AppFieldDate, {
          modelValue: _ctx.period.to,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.period.to) = $event)),
          label: "Till",
          "hide-bottom-space": "",
          class: "q-mr-sm q-pl-md",
          borderless: ""
        }, null, 8, ["modelValue"]),
        _createVNode(_component_q_separator, { vertical: "" }),
        _createVNode(_component_q_btn, {
          loading: _ctx.loading,
          onClick: _ctx.fetchHistory,
          stretch: "",
          flat: "",
          label: "Hämta",
          color: "primary"
        }, null, 8, ["loading", "onClick"]),
        _createVNode(_component_q_separator, { vertical: "" }),
        _createVNode(_component_q_space)
      ]),
      "body-cell-prognosis": _withCtx((props) => [
        _createElementVNode("div", {
          class: _normalizeClass([
          _ctx.isPrognosisOverdue(props.row.ready, props.value) &&
            _ctx.$style.prognosisWarningBox,
        ])
        }, _toDisplayString(props.value), 3)
      ]),
      _: 1
    }, 8, ["columns", "rows", "loading", "action-items"])
  ], 64))
}