
import { VehicleWithdrawal } from '@/types/VehicleWithdrawal'
import { VehicleWithdrawalLog } from '@/types/VehicleWithdrawalLog'
import { format } from 'date-fns'
import { defineComponent, PropType } from 'vue'
import orderBy from 'lodash.orderby'
import { VehicleWithdrawalCause } from '@/types/VehicleWithdrawalCause'
import { VehicleWithdrawalReason } from '@/types/VehicleWithdrawalReason'
import { VehicleWithdrawalDepot } from '@/types/VehicleWithdrawalDepot'
import { VehicleWithdrawalResponsible } from '@/types/VehicleWithdrawalResponsible'
import { Vehicle } from '@/types/Vehicle'

export default defineComponent({
  name: 'VehicleWithdrawalLogModal',

  emits: ['close'],

  props: {
    selectedVehicleWithdrawal: {
      type: Object as PropType<VehicleWithdrawal>,
      required: true,
    },
    causes: {
      type: Array as PropType<VehicleWithdrawalCause[]>,
      required: true,
    },
    reasons: {
      type: Array as PropType<VehicleWithdrawalReason[]>,
      required: true,
    },
    depots: {
      type: Array as PropType<VehicleWithdrawalDepot[]>,
      required: true,
    },
    responsibles: {
      type: Array as PropType<VehicleWithdrawalResponsible[]>,
      required: true,
    },
    vehicles: {
      type: Array as PropType<Vehicle[]>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const closeModal = () => {
      emit('close')
    }

    const mapDependencies = (
      vehicleWithdrawal: Partial<VehicleWithdrawal> | undefined
    ) => {
      if (!vehicleWithdrawal) return {}
      const item = {
        ...vehicleWithdrawal,
        vehicle: props.vehicles.find(
          (y) => y.uuid === vehicleWithdrawal.vehicle_uuid
        )?.name,
        vehicleWithdrawalReason: props.reasons.find(
          (y) => y.uuid === vehicleWithdrawal.vehicle_withdrawal_reason_uuid
        )?.name,
        vehicleWithdrawalCause: props.causes.find(
          (y) => y.uuid === vehicleWithdrawal.vehicle_withdrawal_cause_uuid
        )?.name,
        vehicleWithdrawalDepot: props.depots.find(
          (y) => y.uuid === vehicleWithdrawal.vehicle_withdrawal_depot_uuid
        )?.name,
        vehicleWithdrawalResponsible: props.responsibles.find(
          (y) =>
            y.uuid === vehicleWithdrawal.vehicle_withdrawal_responsible_uuid
        )?.name,
      }

      delete item.vehicle_withdrawal_reason_uuid
      delete item.vehicle_withdrawal_cause_uuid
      delete item.vehicle_withdrawal_depot_uuid
      delete item.vehicle_withdrawal_responsible_uuid
      delete item.vehicle_uuid

      return item
    }

    const columns = [
      {
        align: 'left',
        name: 'method',
        required: true,
        label: 'Typ',
        field: 'method',
        sortable: false,
      },
      {
        align: 'left',
        name: 'created',
        required: true,
        label: 'Loggdatum',
        format: (v: string) => format(new Date(v), 'yyyy-MM-dd HH:mm'),
        field: 'created',
        sortable: false,
      },
      {
        align: 'left',
        name: 'user',
        required: true,
        field: 'user',
        label: 'Användare',
        sortable: false,
      },

      {
        align: 'left',
        name: 'was',
        required: true,
        label: 'Innan ändring',
        field: (v: VehicleWithdrawalLog) =>
          JSON.stringify(mapDependencies(v?.change?.was), null, 2),
        sortable: false,
      },
      {
        align: 'left',
        name: 'change',
        required: true,
        label: 'Efter ändring',
        field: (v: VehicleWithdrawalLog) =>
          JSON.stringify(mapDependencies(v?.change?.updated), null, 2),
        sortable: false,
      },
    ]

    const sortedLog = orderBy(
      props.selectedVehicleWithdrawal.logs || [],
      ['created'],
      ['desc']
    )

    return {
      closeModal,
      columns,
      sortedLog,
    }
  },
})
