
import { defineComponent } from 'vue'
import HistoryTable from '@/components/history/HistoryTable.vue'

export default defineComponent({
  components: {
    HistoryTable,
  },

  name: 'History',
})
