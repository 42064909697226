import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row items-center justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_input, {
    square: _ctx.square,
    label: _ctx.label,
    modelValue: _ctx.internalModel,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.internalModel) = $event)),
    rules: [..._ctx.rules, _ctx.validDateRule],
    mask: _ctx.mask
  }, {
    append: _withCtx(() => [
      _createVNode(_component_q_icon, {
        name: "mdi-calendar-range",
        class: "cursor-pointer"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_popup_proxy, {
            ref: "qDateProxy",
            "transition-show": "scale",
            "transition-hide": "scale"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_date, {
                mask: "YYYY-MM-DD",
                modelValue: _ctx.internalModel,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalModel) = $event))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _withDirectives(_createVNode(_component_q_btn, {
                      label: "Stäng",
                      color: "primary",
                      flat: ""
                    }, null, 512), [
                      [_directive_close_popup]
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }, 512)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["square", "label", "modelValue", "rules", "mask"]))
}