
import { VehicleWithdrawal } from '@/types/VehicleWithdrawal'
import QTable from 'quasar/src/components/table/QTable.js';
import { defineComponent, PropType, ref } from 'vue'
import { exportExcel } from '@/services/exportExcel'

export default defineComponent({
  name: 'AppFullViewTable',

  props: {
    rows: {
      type: Array as PropType<VehicleWithdrawal[]>,
      required: true,
    },
    columns: {
      type: Array as PropType<QTable['columns'] | undefined>,
      required: true,
    },
    exportFileName: {
      type: String,
      required: true,
    },
    loading: Boolean,
    actionItems: Array as PropType<
      {
        label: string
        icon: string
        action: (row: VehicleWithdrawal) => void
        visible?: (row: VehicleWithdrawal) => boolean
      }[]
    >,
    customHeight: {
      type: Boolean,
      default: () => false,
    },
  },

  setup(props) {
    const exporting = ref(false)
    const filterText = ref('')
    function onExport() {
      exporting.value = true

      const exportData = {
        name: props.exportFileName,
        sheets: [
          {
            name: props.exportFileName,
            data: props.rows.map((item) => {
              return (props.columns || []).reduce<{ [name: string]: unknown }>(
                (acc, col) => {
                  if (!col) return acc
                  const value =
                    typeof col.field === 'function'
                      ? col.field(item)
                      : item[col.name as keyof VehicleWithdrawal]

                  if (col.format) {
                    acc[col.label] = col.format(value, null)
                  } else {
                    acc[col.label] = value
                  }

                  return acc
                },
                {}
              )
            }),
          },
        ],
      }
      exportExcel(exportData)
      exporting.value = false
    }

    const onPrint = () => {
      window.open(
        `${window.location.origin}/vehicle-report-print`,
        'vehicle-withdrawal-print',
        '_blank'
      )
    }

    const filterActionItems = (
      actionItem: { visible?: (row: VehicleWithdrawal) => boolean },
      row: VehicleWithdrawal
    ) => {
      return typeof actionItem.visible !== 'undefined'
        ? actionItem.visible(row)
        : true
    }

    return {
      filterText,
      exporting,
      onExport,
      onPrint,
      filterActionItems,
    }
  },
})
